export default {
  sales: {
    index: '/sales'
  },
  analytics: {
    index: '/analytics'
  },
  manufacturers: {
    index: '/manufacturers',
    create() {
      return `${this.index}/create`;
    },
    edit(id) {
      return id !== undefined ? `${this.index}/${id}/edit` : `${this.index}/:id/edit`;
    }
  },
  products: {
    index: '/products',
    create() {
      return `${this.index}/create`;
    },
    edit(id) {
      return id !== undefined ? `${this.index}/${id}/edit` : `${this.index}/:id/edit`;
    },
    logs(id){
      return id !== undefined ? `${this.index}/${id}/logs` : `${this.index}/:id/logs`;
    },
    importJob(id) {
      return id !== undefined ? `${this.index}/import/${id}` : `${this.index}/import/:id`;
    }
  },
  listings: {
    index: '/listings',
    create() {
      return `${this.index}/create`;
    },
    edit(id) {
      return id !== undefined ? `${this.index}/${id}/edit` : `${this.index}/:id/edit`;
    },
    images(id) {
      return id !== undefined ? `${this.index}/${id}/images` : `${this.index}/:id/images`;
    }
  },
  unitEconomics: {
    index: '/unit-economics',
    create() {
      return `${this.index}/create`;
    },
    edit(id) {
      return id !== undefined ? `${this.index}/${id}` : `${this.index}/:id`;
    }
  },
  productLogs: {
    index: '/product-logs/:id',
  },

  userManagement: {
    index: '/user-management'
  },
  pimSettings: { index: '/pim-settings' },
  analyticsSettings: { 
    index: '/analytics-settings',
    create() {
      return `${this.index}/create`;
    },
    edit(id) {
      return id !== undefined ? `${this.index}/${id}/edit` : `${this.index}/:id/edit`;
    }, 
  },
  contentSettings: {
    index: '/content-settings'
  },
  backup: {
    index: '/back-up'
  },
  copyData: {
    index: '/copy-data'
  },
  costSettings: {
    index: '/cost-settings'
  },
  documentUploads: {
    index: '/documents-upload'
  },
  mediaBudget:{
    index: '/media-budget'
  }
};
