import React from 'react';

import routes from './routes';

/** ---------- MANUFACTURER ------------ */
export const addManufacturer = {
  name: {
    inputType: 'input',
    isValid: false,
    inputConf: {
      placeholder: '',
      name: 'name',
      type: 'text'
    },
    validation: {
      required: true
    },
    label: 'Name',
    value: ''
  },
  commission_type: {
    inputType: 'selectSingleTarget',
    isValid: true,
    inputConf: {
      placeholder: '',
      name: 'commission_type',
      type: 'select'
    },
    validation: {
      required: true
    },
    label: 'Commision Type',
    list: [
      { label: 'Goods consignment', value: 'Goods consignment' },
      { label: 'Goods purchase', value: 'Goods purchase' },
      { label: 'No commission', value: 'No commission' }
    ],
    value: [{ label: 'Goods consignment', value: 'Goods consignment' }]
  },
  commission_percentage: {
    inputType: 'input',
    isValid: false,
    inputConf: {
      placeholder: '',
      name: 'commission_percentage',
      type: 'number'
    },
    validation: {
      required: true
    },
    label: 'Commission (in %)',
    value: '0'
  },
  margin_percentage: {
    inputType: 'input',
    isValid: false,
    inputConf: {
      placeholder: '',
      name: 'margin_percentage',
      type: 'number'
    },
    validation: {
      required: false
    },
    label: 'Margin (in %)',
    value: ''
  },
  status: {
    inputType: 'selectSingleTarget',
    isValid: true,
    inputConf: {
      placeholder: '',
      name: 'status',
      type: 'select'
    },
    validation: {
      required: true
    },
    label: 'Status',
    list: [
      { label: 'Active', value: 1 },
      { label: 'Archived', value: 0 }
    ],
    value: [{ label: 'Active', value: 1 }]
  },
  alt_name: {
    inputType: 'input',
    isValid: false,
    inputConf: {
      placeholder: '',
      name: 'alt_name',
      type: 'text'
    },
    validation: {
      required: true
    },
    label: 'Short Name',
    value: ''
  },
  type: {
    inputType: 'selectSingleTarget',
    isValid: true,
    inputConf: {
      placeholder: '',
      name: 'type',
      type: 'select'
    },
    validation: {
      required: true
    },
    label: 'Company type',
    list: [
      { label: 'EMS', value: 1 },
      { label: 'APS', value: 2 }
    ],
    value: []
  },
  service_type: {
    inputType: 'selectSingleTarget',
    isValid: true,
    inputConf: {
      placeholder: '',
      name: 'service_type',
      type: 'select'
    },
    validation: {
      required: false
    },
    label: 'Service Type',
    list: [
      { label: 'Acceleration', value: 'Acceleration' },
      { label: 'Service', value: 'Service' },
      { label: 'Service Plus', value: 'Service Plus' }
    ],
    value: [{ label: 'Acceleration', value: 'Acceleration' }]
  }
};

export const editManufacturer = {
  name: {
    inputType: 'input',
    isValid: false,
    inputConf: {
      placeholder: '',
      name: 'name',
      type: 'text'
    },
    validation: {
      required: true
    },
    label: 'Name',
    value: ''
  },
  commission_type: {
    inputType: 'selectSingleTarget',
    isValid: true,
    inputConf: {
      placeholder: '',
      name: 'commission_type',
      type: 'select'
    },
    validation: {
      required: true
    },
    label: 'Commision Type',
    list: [
      { label: 'Goods consignment', value: 'Goods consignment' },
      { label: 'Goods purchase', value: 'Goods purchase' },
      { label: 'No commission', value: 'No commission' }
    ],
    value: []
  },
  commission_percentage: {
    inputType: 'input',
    isValid: false,
    inputConf: {
      placeholder: '',
      name: 'commission_percentage',
      type: 'number'
    },
    validation: {
      required: true
    },
    label: 'Commission (in %)',
    value: '0'
  },
  status: {
    inputType: 'selectSingleTarget',
    isValid: true,
    inputConf: {
      placeholder: '',
      name: 'status',
      type: 'select'
    },
    validation: {
      required: true
    },
    label: 'Status',
    list: [
      { label: 'Active', value: 1 },
      { label: 'Archived', value: 0 }
    ],
    value: [],
  },
  alt_name: {
    inputType: 'input',
    isValid: false,
    inputConf: {
      placeholder: '',
      name: 'alt_name',
      type: 'text'
    },
    validation: {
      required: true
    },
    label: 'Short Name',
    value: ''
  },
  type: {
    inputType: 'selectSingleTarget',
    isValid: true,
    inputConf: {
      placeholder: '',
      name: 'type',
      type: 'select'
    },
    validation: {
      required: true
    },
    label: 'Company type',
    list: [
      { label: 'EMS', value: 1 },
      { label: 'APS', value: 2 }
    ],
    value: []
  },
  service_type: {
    inputType: 'selectSingleTarget',
    isValid: true,
    inputConf: {
      placeholder: '',
      name: 'service_type',
      type: 'select'
    },
    validation: {
      required: false
    },
    label: 'Service Type',
    list: [
      { label: 'Acceleration', value: 'Acceleration' },
      { label: 'Service', value: 'Service' },
      { label: 'Service Plus', value: 'Service Plus' }
    ],
    value: [{ label: 'Acceleration', value: 'Acceleration' }]
  }
};

/** ---------- COMPONENTS ------------ */
export const tableFilterFields = {
  count: {
    inputType: 'selectSingleTarget',
    inputConf: {
      placeholder: '',
      name: 'count',
      type: 'select'
    },
    label: 'Show entries: ',
    list: [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '30', value: 30 },
      { label: '40', value: 40 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
      { label: '500', value: 500 },
      { label: '1000', value: 1000 }
    ],
    value: [{ label: '10', value: 10 }]
  },
  search: {
    inputType: 'input',
    inputConf: {
      placeholder: '',
      name: 'search',
      type: 'text'
    },
    label: 'Search: ',
    value: ''
  }
};


// STATUSES ENUM
export const STATUSES_ENUM = {
  archived: 0,
  active: 1,
  in_setup: 2,
  ok: 3,
  error: 4
}

// STATUSES
export const PRODUCT_STATUSES = [
  <span className="status-badge status-badge-archived">Archived</span>,
  <span className="status-badge status-badge-active">Active</span>,
  <span className="status-badge status-badge-insetup">In Setup</span>,
  <span className="status-badge status-badge-active">Phase out</span>,
  <span className="status-badge status-badge-insetup">Ready to list</span>,
  <span className="status-badge status-badge-insetup">Listed</span>
];

export const STATUSES = [
  <span className="status-badge status-badge-archived">Archived</span>,
  <span className="status-badge status-badge-active">Active</span>,
  <span className="status-badge status-badge-insetup">In Setup</span>,
  <span className="status-badge status-badge-active">OK</span>,
  <span className="status-badge status-badge-canceled">Error</span>,
];

export const IMPORT_STATUSES = [
  undefined,
  <span className="status-badge status-badge-preparation">Preview prep</span>,
  <span className="status-badge status-badge-ready">Preview ready</span>,
  <span className="status-badge status-badge-insetup">Importing</span>,
  <span className="status-badge status-badge-partially">Completed (partially)</span>,
  <span className="status-badge status-badge-active">Completed</span>,
  <span className="status-badge status-badge-canceled">Canceled</span>
];

export const DOCUMENT_UPLOAD_STATUSES = [
  undefined,
  <span className="status-badge status-badge-insetup">Uploading</span>,
  <span className="status-badge status-badge-active">Uploaded</span>,
  <span className="status-badge status-badge-canceled">Not uploaded</span>
];

export const CURRENCIES = ['EUR', 'GBP', "PLN", "SEK"];
export const CURRENCY_SYMBOLS = ['€', '£', "zł", "kr"];

export const STATUS_OPTIONS = [
  { id: 0, name: 'Archived' },
  { id: 1, name: 'Active' }
];

export const MARKETPLACE_STATUS_OPTIONS = [
  { id: 0, name: 'Archived' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'Paused' }
];

export const ACCOUNT_TYPE_OPTIONS = [
  { id: 'Seller', name: 'Seller' },
  { id: 'Vendor', name: 'Vendor' }
];

export const CURRENCIES_OPTIONS = [
  { id: 0, name: 'EUR' },
  { id: 1, name: 'GBP' },
  { id: 2, name: "SEK"},
  { id: 3, name: "PLN"}
];

export const PRODUCT_STATUS_OPTIONS = [
  { id: 2, name: 'In Setup' },
  { id: 4, name: 'Ready to list'},
  { id: 5, name: 'Listed'},
  { id: 1, name: 'Active' },
  { id: 3, name: 'Phase out'},
  { id: 0, name: 'Archived' }
];

export const PRODUCT_STATUS_OPTIONS_SHOWN = [
  { id: 2, name: 'In Setup' },
  { id: 4, name: 'Ready to list'},
  { id: 5, name: 'Listed'},
  { id: 1, name: 'Active' },
  { id: 3, name: 'Phase out'},
  { id: 0, name: 'Archived' }
];

export const SEASONALITY = [
  { id: 'No Seasonality', name: 'No Seasonality' },
  { id: 'Christmas', name: 'Christmas' },
  { id: 'Easter', name: 'Easter' },
  { id: 'Winter', name: 'Winter'},
  { id: 'Summer', name: 'Summer'},
  { id: 'Spring', name: 'Spring'},
  { id: 'Autumn', name: 'Autumn'},
];


export const marketplace_id_typeS = [
  { id: 0, name: 'ASIN' },
  { id: 1, name: 'SKU' },
  { id: 2, name: 'MLID' },
  { id: 3, name: 'noASIN' }
];

export const PRODUCT_CATEGORIES_OPTIONS = [
  { label: 'Hazmat', value: 'Hazmat' },
  { label: 'Pharmacy', value: 'Pharmacy' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'Food', value: 'Food' },
  { label: 'Amazon', value: 'Amazon' },
];
export const PRODUCT_EXPORT_OPTIONS = [
  { label: 'Product export', value: 'product' },
  { label: 'ASIN/SKU export', value: 'cid' },
  { label: 'ASIN/SKU update export', value: 'cid_update' },
  { label: 'Product update export', value: 'product_update' },
  { label: 'Product price update export', value: 'product_price_update'},
  { label: 'Parent product export', value: 'product_parent'},
];

export const CONTENT_EXPORT_OPTIONS = [
  { label: 'Amazon', value: 'amazon' },
  // { label: 'Ebay', value: 'ebay' },
  // { label: 'Kaufland', value: 'kaufland' },
  // { label: 'Otto', value: 'otto' },
];

export const UPLOAD_DOCS_OPTIONS = [
  { label: 'amazon.de', value: 'de' },
  { label: 'amazon.es', value: 'es' },
  { label: 'amazon.fr', value: 'fr' },
  { label: 'amazon.co.uk', value: 'uk' },
  { label: 'amazon.it', value: 'it' },
  { label: 'amazon.nl', value: 'nl' },
  { label: 'amazon.se', value: 'se' },
];

export const PRODUCT_IMPORT_OPTIONS = [
  { label: 'Product import', value: 'product' },
  // { label: 'Price import', value: 'price' },
  { label: 'ASIN/SKU import', value: 'cid' },
  { label: 'ASIN/SKU update import', value: 'cid_update' },
  { label: 'Bundled items import', value: 'bundle' },
  { label: '', value: 'split',isDisabled: true},
  { label: 'Product update import', value: 'product_update' },
  { label: 'Product price update import', value: 'product_price_update' },
];

export const IMPORT_SELECT_OPTIONS = [
  { label: 'Product import', value: 'product' },
  // { label: 'Price import', value: 'price' },
  { label: 'ASIN/SKU import', value: 'cid' },
  { label: 'Bundled items import', value: 'bundle' },
  { label: '', value: 'split',isDisabled: true},
  { label: 'Product update import', value: 'product_update' },
  { label: 'Product price update import', value: 'product_price_update' },
];

export const USER_TYPE_OPTIONS = [
  { label: 'Manufacturer', value: 1 },
  { label: 'Internal', value: 2 },
  { label: 'Demo', value: 3 },
  { label: 'Retail', value: 4 },
];

export const PRODUCT_IMPORT_TEMPLATES = {
  product: 'https://staging-api.ebazaaris.io/files/import_sample_products.xlsx',
  price: 'https://staging-api.ebazaaris.io/files/import_sample_prices.xlsx',
  cid: 'https://staging-api.ebazaaris.io/files/import_sample_asin-sku.xlsx',
  bundle: 'https://staging-api.ebazaaris.io/files/import_sample_bundled_items.xlsx',
  product_update: 'https://staging-api.ebazaaris.io/files/import_sample_products_update.xlsx',
  product_price_update: 'https://staging-api.ebazaaris.io/files/import_sample_products_price_update_v2.xlsx',
  content_import: 'https://staging-api.ebazaaris.io/files/import_sample_content.xlsx',
  amazon: 'https://staging-api.ebazaaris.io/files/import_sample_amazon.xlsx',
  ebay: 'https://staging-api.ebazaaris.io/files/import_sample_ebay.xlsx',
  kaufland: 'https://staging-api.ebazaaris.io/files/import_sample_kaufland.xlsx',
  otto: 'https://staging-api.ebazaaris.io/files/import_sample_otto.xlsx',
  cid_update: 'https://staging-api.ebazaaris.io/files/import_sample_asin-sku_update.xlsx',
};

export const PROD_PRODUCT_IMPORT_TEMPLATES = {
  product: 'https://azure-api.ebazaaris.io/files/import_sample_products.xlsx',
  price: 'https://azure-api.ebazaaris.io/files/import_sample_prices.xlsx',
  cid: 'https://azure-api.ebazaaris.io/files/import_sample_asin-sku.xlsx',
  bundle: 'https://azure-api.ebazaaris.io/files/import_sample_bundled_items.xlsx',
  product_update: 'https://azure-api.ebazaaris.io/files/import_sample_products_update.xlsx',
  product_price_update: 'https://azure-api.ebazaaris.io/files/import_sample_products_price_update_v2.xlsx',
  content_import: 'https://azure-api.ebazaaris.io/files/import_sample_content.xlsx',
  amazon: 'https://azure-api.ebazaaris.io/files/import_sample_amazon.xlsx',
  ebay: 'https://azure-api.ebazaaris.io/files/import_sample_ebay.xlsx',
  kaufland: 'https://azure-api.ebazaaris.io/files/import_sample_kaufland.xlsx',
  otto: 'https://azure-api.ebazaaris.io/files/import_sample_otto.xlsx',
  cid_update: 'https://azure-api.ebazaaris.io/files/import_sample_asin-sku_update.xlsx',
};

export const DOCUMENTS_UPLOAD_TYPES = [
  {label: 'Billing ebazaaris sira seller transactions', value: 'billing_ebazaaris_sira_seller_transactions' },
  {label: 'Billing warehousing custom costs', value: 'billing_warehousing_custom_costs' },
  {label: 'Billing bol com transaciotns', value: 'billing_bol_com_transaciotns' },
  {label: 'Advertising Kaufland campaigns', value: 'advertising_kaufland_campaigns' },
  {label: 'Advertising Otto campaigns', value: 'advertising_otto_campaigns' },
  {label: 'Dekodi_basisliste', value: 'dekodi_basisliste' },
  {label: 'Dekodi_korrektur_intrastat', value: 'dekodi_korrektur_intrastat' },
  {label: 'Dekodi_korretur_ust', value: 'dekodi_korretur_ust' },
  {label: 'Dekodi_fallback_skus', value: 'dekodi_fallback_skus' },
  {label: 'PnL_accounts_pnl', value: 'pnL_accounts_pnl' },
  {label: 'PnL_accounts_cash', value: 'pnL_accounts_cash' },
  {label: 'PnL_actuals_cash', value: 'pnL_actuals_cash' },
  {label: 'PnL_actuals_pnl', value: 'pnL_actuals_pnl' },
  {label: 'PnL_budget_pnl', value: 'pnL_budget_pnl' },
]

export const DOCUMENTS_UPLOAD_TEMPLATES = {
  billing_ebazaaris_sira_seller_transactions: 'https://staging-api.ebazaaris.io/upload_files/NF_01_2022_1_31_DE_ebazaaris_sira_seller.csv',
  billing_warehousing_custom_costs: 'https://staging-api.ebazaaris.io/upload_files/2022-01_Warhousing_Custom_Costs.xlsx',
  billing_bol_com_transaciotns: 'https://staging-api.ebazaaris.io/upload_files/2020_01_bol.com.xlsx',
  advertising_kaufland_campaigns: 'https://staging-api.ebazaaris.io/upload_files/08_01_31_Kaufland_Campaigns.xlsx',
  advertising_otto_campaigns: 'https://staging-api.ebazaaris.io/upload_files/Otto_20220813_20220831.csv',
  dekodi_basisliste: 'https://staging-api.ebazaaris.io/upload_files/ft_dekodi_basisliste.xlsx',
  dekodi_korrektur_intrastat: 'https://staging-api.ebazaaris.io/upload_files/FC_Dekodi_Model_dekodi_korrektur_intrastat.xlsx',
  dekodi_korretur_ust: 'https://staging-api.ebazaaris.io/upload_files/FC_Dekodi_Model_dekodi_korrektur_ust.xlsx',
  dekodi_fallback_skus: 'https://staging-api.ebazaaris.io/upload_files/IMPORT_01-1022_finance_ft_dekodi_fallback_skus.xlsx',
  pnL_accounts_pnl: 'https://staging-api.ebazaaris.io/upload_files/dim_accounts_pnl_STATUS_20221108.xlsx',
  pnL_accounts_cash: 'https://staging-api.ebazaaris.io/upload_files/dim_datev_cash_accounts_STATUS_20221108.xlsx',
  pnL_actuals_cash: 'https://staging-api.ebazaaris.io/upload_files/finance_ft_cash_actuals_UNTIL_0922_STATUS_20221108.xlsx',
  pnL_actuals_pnl: 'https://staging-api.ebazaaris.io/upload_files/finance_ft_pnl_actuals_UNTIL_0922_STATUS_20221108.xlsx',
  pnL_budget_pnl: 'https://staging-api.ebazaaris.io/upload_files/budget_pnl.csv',
};

// PRODUCT FORMS

export const PRODUCT_FORMS = {
  productInformation: {
    title: 'Product information'
  },
  productCategorization: {
    title: 'Product categorization'
  },
  logisticsInformation: {
    title: 'Logistics information'
  },
  marketplaceInformation: {
    title: 'Marketplace Information'
  },
  prices: {
    title: 'Prices'
  },
  customsInformation: {
    title: 'Customs Information'
  },
  bundledItems: {
    title: 'Bundled items'
  },
  bundleInformation: {
    title: 'Item\'s connected bundles'
  },
  variations: {
    title: 'Variations'
  },
  attributes:{
    title: "Product attributes"
  },
  pharmacy_infomation:{
    title: "Pharmacy information"
  },
  documents: {
    title: 'Documents'
  }
};

export const PRODUCT_LISTING_FORMS = {
  productTitle: {
    title: 'Title'
  },
  productSubtitle: {
    title: 'Subtitle'
  },
  productBullets: {
    title: 'Bullets'
  },
  productKeywords: {
    title: 'Keywords'
  },
  productDescription: {
    title: 'Description'
  },
  productImages: {
    title: 'Images'
  },
  productDocuments: {
    title: 'Documents'
  },
  productCharacteristics: {
    title: 'Characteristics'
  },
  richContent: {
    title: 'Rich content'
  },
  listingCateogry: {
    title: 'Listing category'
  },
  productRichContent: {
    title: "Rich content"
  }
};

// Sections

export const sections = [
  {
    type: 'Brand Management',
    name: 'Analytics',
    icon: 'fas fa-chart-bar',
    subsections: [],
  },
  {
    type: 'Brand Management',
    name: 'Retail',
    icon: 'fas fa-chart-bar',
    subsections: []
  },
  {
    type: 'Brand Management',
    name: 'Media',
    icon: 'fas fa-bullhorn',
    subsections: [
      {
        id: 55,
        access: 'readonly',
        name: 'Media Budget',
        path: routes.manufacturers.index,
        position: 99
      }
    ]
  },
  {
    type: 'Brand Management',
    name: 'Inventory',
    icon: 'fas fa-truck',
    subsections: []
  },
  {
    type: 'Brand Management',
    name: 'Pricing',
    icon: 'fas fa-tag',
    subsections: []
  },
  {
    type: 'Internal Analytics',
    name: 'Operations',
    icon: 'fas fa-wrench',
    subsections: []
  },
  {
    type: 'Internal Analytics',
    name: 'Controlling',
    icon: 'fas fa-chart-pie',
    subsections: []
  },
  {
    type: 'Brand Management',
    name: 'Digital Shelf',
    icon: 'fas fa-binoculars',
    subsections: []
  },
  {
    type: 'Internal Analytics',
    name: 'Billing',
    icon: 'fas fa-euro-sign',
    subsections: []
  },
  {
    type: 'Internal Analytics',
    name: 'Accounting',
    icon: 'fas fa-file',
    subsections: []
  },
  {
    type: 'Internal Analytics',
    name: 'PnL',
    icon: 'fas fa-chart-pie',
    subsections: []
  },
  {
    type: 'Brand Management',
    name: 'PIM',
    icon: 'fas fa-box-open',
    subsections: [
      {
        id: 21,
        access: 'full',
        name: 'Manufacturers',
        path: routes.manufacturers.index,
        position: 1
      },
      {
        id: 22,
        access: 'full',
        name: 'Products',
        path: routes.products.index,
        position: 6
      },
      {
        id: 23,
        access: 'full',
        name: 'Products > PDP > Marketplaces',
        position: 7

        // path: routes.products.index
      },
      {
        id: 24,
        access: 'full',
        name: 'Products > PDP > Prices',
        position: 8

        // path: routes.products.index
      },
      {
        id: 50,
        access: 'full',
        name: 'Products > PDP > Product information',
        position: 9

        // path: routes.products.index
      },
      {
        id: 51,
        access: 'full',
        name: 'Products > PDP > Product categorization',
        position: 9

        // path: routes.products.index
      },
      {
        id: 52,
        access: 'full',
        name: 'Products > PDP > Logistics information',
        position: 9

        // path: routes.products.index
      },
      {
        id: 53,
        access: 'full',
        name: 'Products > PDP > Customs Information',
        position: 9

        // path: routes.products.index
      },
      {
        id: 54,
        access: 'full',
        name: 'Products > PDP > Content',
        position: 9

        // path: routes.products.index
      },
      {
        id: 55,
        access: 'full',
        name: 'Products > PDP > Unit economics',
        position: 9

        // path: routes.products.index
      },
      {
        id: 56,
        access: 'full',
        name: 'Products > PDP > Update log',
        position: 9

        // path: routes.products.index
      },
      {
        id: 25,
        access: 'full',
        name: 'Products > Content',
        position: 9

        // path: '/listings'
      },
      
      {
        id: 26,
        access: 'full',
        name: 'Manufacturers > Products',
        position: 2

      },
      {
        id: 27,
        access: 'full',
        name: 'Manufacturers > Brands',
        position: 3

      },
      {
        id: 28,
        access: 'full',
        name: 'Manufacturers > Targets',
        position: 4

      },
      {
        id: 29,
        access: 'full',
        name: 'Manufacturers > Settings',
        position: 5

      },
    ]
  },
  {
    name: 'Settings',
    icon: 'fas fa-cog',
    subsections: [
      {
        id: 31,
        access: 'full',
        name: 'User management',
        path: routes.userManagement.index,
        position: 10
      },
      {
        id: 32,
        access: 'full',
        name: 'PIM settings',
        path: routes.pimSettings.index,
        position: 11
      },
      {
        id: 33,
        access: 'full',
        name: 'Analytics settings',
        path: routes.analyticsSettings.index,
        position: 12
      },
      // {
      //   id: 34,
      //   access: 'full',
      //   name: 'Content settings',
      //   path: routes.contentSettings.index,
      //   position: 13
      // },

      {
        id: 37,
        access: 'readonly',
        name: 'Cost settings',
        path: routes.costSettings.index,
        position: 14
      }
    ]
  },
  // {
  //   type: 'Brand Management',
  //   name: 'Uploads',
  //   icon: 'fas fa-cloud-upload-alt',
  //   subsections: [
  //     {
  //       id: 41,
  //       access: 'full',
  //       name: 'Documents upload',
  //       path: routes.documentUploads.index,
  //       position: 15
  //     },
  //   ]
  // }
];




export const AMAZON_MARKETPLACES = [1, 11, 12, 13, 14, 15, 16, 18];