import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';

/** Apollo */
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import Routes from '../../../lib/constants/routes';
import Table from '../../../components/table/Table';
import { queryStringHandler, fetchData } from '../../../services/util/dataHandlerService';

import { STATUSES_ENUM, IMPORT_STATUSES, PRODUCT_STATUSES } from '../../../lib/constants/fields';

/** Components */
import { getShippingUnitsTemp, getFulfilmentCosts as QUERY } from '../../../services/api/queryService';
import { render } from 'react-dom';
import ModalForm from '../../../components/modal/ModalForm';
import ShippingCostModal from './shippingCostModal';

import "../costSettings.scss"

const ShippingCost = () => {
    const [ loader, setLoader ] = useState(true);
    const [ itemId, setItemId ] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [fulfilmentCosts, setFulfilmentCosts] = useState([]);
    const [shippingUnits, setShippingUnits] = useState([]);

    const history = useHistory();
    const location = useLocation();

    let { loading, data, refetch, error } = useQuery(QUERY);

    let { data : shippingUnitsTempData } = useQuery(getShippingUnitsTemp);

    useEffect(() => {
        if (shippingUnitsTempData) {
            setShippingUnits([...shippingUnitsTempData.shippingUnitsTemp])
        }
    }, [shippingUnitsTempData])

    useEffect(() => {
        if (data && data.fulfilmentCosts) {
            data.fulfilmentCosts.map(x => {
                if( typeof(x.unit_data) == 'string'){
                    x.unit_data = JSON.parse(x.unit_data.replaceAll("'", '"'))
                }

                x.status = +x.status
                x.cost_order_received = Number(x.cost_order_received).toFixed(2)
                x.bundle_article_pick = Number(x.bundle_article_pick).toFixed(2)
                x.cost_order_shipping = Number(x.cost_order_shipping).toFixed(2)
                x.cost_return_article = Number(x.cost_return_article).toFixed(2)
                x.single_article_pick = Number(x.single_article_pick).toFixed(2)
                
                x.total_cost_article_returned = x.unit_data.fulfillment_vendor == 'FBA' ? Number(x.cost_return_article).toFixed(2) : Number(+x.cost_order_received + +x.single_article_pick + +x.cost_order_shipping + +x.cost_return_article + +x.surcharge).toFixed(2);
                x.total_cost_article_shipped = x.unit_data.fulfillment_vendor !== 'FBA' ? Number(+x.cost_order_received + +x.single_article_pick + +x.cost_order_shipping + +x.surcharge).toFixed(2) : Number(+x.cost_order_received + +x.single_article_pick + +x.cost_order_shipping).toFixed(2);
            })

            setFulfilmentCosts([...data.fulfilmentCosts])
            setLoader(false)
        }
    }, [loading])

    const currencyIcon = {
        eur: '€',
        gbp: '£',
        sek: 'kr',
        pln: 'zł'
    }

    console.log(fulfilmentCosts)

    return (
        <>
            {loader ? (
                <div className="spinner absolute">Spinner</div>
            ) : (
                <div className="sections-page-table" id="shippingCost">
                    <div className="row">
                        <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {/* <div className="d-flex mb-2">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setItemId(null);
                                            setOpenModal(true);
                                        }}
                                        >
                                        Create New
                                    </button>
                                </div> */}
                                <div className="table-responsive">
                                    <table id="basic-datatable" className="table table-hover dt-responsive nowrap fulfilment-costs">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Fulfillment vendor</th>
                                                <th>Carrier</th>
                                                <th>Marketplace</th>
                                                <th style={{maxWidth: '330px'}}>Unit</th>
                                                <th>HxWxD</th>
                                                <th style={{maxWidth: '120px', whiteSpace: 'unset'}}>Buy price/shipped</th>
                                                <th style={{maxWidth: '120px', whiteSpace: 'unset'}}>Margin article shipped</th>
                                                <th style={{maxWidth: '120px', whiteSpace: 'unset'}}>Sell price/shipped</th>
                                                <th style={{maxWidth: '120px', whiteSpace: 'unset'}}>Buy price/returned</th>
                                                <th style={{maxWidth: '120px', whiteSpace: 'unset'}}>Margin article returned</th>
                                                <th style={{maxWidth: '120px', whiteSpace: 'unset'}}>Sell price/returned</th>
                                                <th style={{maxWidth: '120px', whiteSpace: 'unset'}}>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fulfilmentCosts && fulfilmentCosts.length > 0 && fulfilmentCosts.map((x, i) => (
                                                <tr key={i}>
                                                    <td>{ x.id }</td>
                                                    <td><span>{ x.unit_data.fulfillment_vendor }</span></td>
                                                    <td><span>{ x.unit_data.shipping_carrier }</span></td>
                                                    <td><span>{ x.unit_data.marketplace?.toLowerCase() }</span></td>
                                                    <td style={{maxWidth: '330px', padding:'0.3rem 0.2rem'}}>
                                                        <span>
                                                            { (x.unit_data.shipping_carrier == 'DHL' ? 
                                                                `${x.unit_data.shipping_carrier} ${x.unit_size} (${x.dimension_operator} ${x.dimension_width_mm}x${x.dimension_height_mm}x${x.dimension_depth_mm} cm, ${x.dimension_operator} ${x.weight_g}g)`
                                                                 :
                                                                 (x.unit_data.shipping_carrier == 'DPD' ?   
                                                                 `${x.unit_data.shipping_carrier} ${x.unit_size} (Maximum Any Side: 175 cm, ${x.weight_g}g)`
                                                                 :
                                                                 x.unit_data.unit_size??x.unit_data.fbm_size??x.unit_data.unit_weight)) }
                                                            {/* { x.unit_data.shipping_carrier == 'DPD' && x.unit_data.fulfillment_vendor.includes('S&S') ? '(excl. hazmat)' : ''}  */}
                                                            { (x.unit_data.fulfillment_vendor == 'FBA' || x.unit_data.fulfillment_vendor == 'FBA Small & Light') ? `(${x.unit_data.weight_g}g)` : ''}</span></td>
                                                    <td><span>{x.unit_data.shipping_carrier == 'DPD' ? 'Maximum Any Side: 175 cm' : (x.dimension_height_mm && x.dimension_height_mm > 0 ? `${ Number(x.dimension_width_mm) }x${ Number(x.dimension_height_mm) }x${ Number(x.dimension_depth_mm) }cm` : '-') } </span></td>
                                                    <td><span>{  parseFloat(Number(+x.cost_order_received + +x.single_article_pick + +x.cost_order_shipping).toFixed(2)).toFixed(2) } { currencyIcon[x.unit_data.currency?.toLowerCase()] } </span></td>
                                                    <td><span>{ Number(x.cost_article_shipped_percentage) } %</span></td>
                                                    <td><span>{  parseFloat(Number(+x.cost_order_received + +x.single_article_pick + +x.cost_order_shipping).toFixed(2) * (1 + +Number(+x.cost_article_shipped_percentage/100).toFixed(2))).toFixed(2) } { currencyIcon[x.unit_data.currency?.toLowerCase()] }</span></td>
                                                    <td><span>{ x.unit_data.fulfillment_vendor != 'FBA' && x.unit_data.fulfillment_vendor != 'FBM Small & Light' ? parseFloat(+x.cost_order_received + +x.single_article_pick + +x.cost_return_article).toFixed(2) : parseFloat(x.total_cost_article_returned).toFixed(2) } { currencyIcon[x.unit_data.currency?.toLowerCase()] } </span></td>
                                                    <td><span>{ Number(x.cost_article_returned_percentage) } %</span></td>
                                                    <td><span>{  (parseFloat(x.unit_data.fulfillment_vendor != 'FBA' && x.unit_data.fulfillment_vendor != 'FBM Small & Light' ? parseFloat(+x.cost_order_received + +x.single_article_pick + +x.cost_return_article).toFixed(2) : parseFloat(x.total_cost_article_returned).toFixed(2)) * (1 + +Number(+x.cost_article_returned_percentage/100).toFixed(2))).toFixed(2) } { currencyIcon[x.unit_data.currency?.toLowerCase()] }</span></td>
                                                    <td>{ PRODUCT_STATUSES[+x.status] }</td> 
                                                    <td>
                                                        <span>
                                                            <i
                                                                id={x.id}
                                                                className="fas fa-edit"
                                                                style={{ cursor: 'pointer' }}
                                                                data-toggle="modal"
                                                                data-target="#modal"
                                                                onClick={e => {
                                                                    setItemId(x);
                                                                    setOpenModal(true);
                                                                }}
                                                            />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}

            {openModal && (
                <ModalForm setModalOpen={setOpenModal}>
                    <ShippingCostModal
                        itemId={itemId}
                        shippingUnits={shippingUnits}
                        sections={[]}
                        setModalOpen={() => {setOpenModal(false); } }
                        refetch={refetch} 
                    />
                </ModalForm>
            )}
        </>

        );
}

export default ShippingCost;



