import React, { memo, useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import { STATUSES, IMPORT_STATUSES, PRODUCT_STATUSES, DOCUMENT_UPLOAD_STATUSES, PRODUCT_STATUS_OPTIONS } from '../../../lib/constants/fields';

/** Styles */
import './Td.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { convertDataToOptions, convertNameToObject, convertTitleToOptions, createDefaultOption } from '../../../services/util/dataHandlerService';
import MarketplaceIssuesModal from '../../../view/product/MarketplaceIssuesModal';

const Td = ({ index, field, value, oldValue, to = '', onClick, moduleName, item, notClickable, isListing, selectData, brands, saveChange, submitForm, editItem, editable }) => {
  const odlValue = value;
  if (field === 'commission_percentage' || field === 'margin_percentage') {
    value += '%';
  }
  const[show, setShow] = useState(null)
  const [ openMarketplaceIssuesModal, setOpenMarketplaceIssuesModal ] = useState(false);
  
  const selectRef = useRef();

  useEffect(() => {
    if(!editItem){
      setShow(null)
    }
  }, [editItem])

  const showInputField = f => {
    if(!editable) return
    
    setShow(f)
    saveChange({index, value: f, field: 'edit'})
  }
  
  const types = [
      { label: 'EMS', value: 1 },
      { label: 'APS', value: 2 }
  ]

  const taxCodesData = [
    "A_BABY_BIBCLOTH", 
    "A_BABY_CARSEAT", 
    "A_BABY_NAPPIES", 
    "A_BOOK_ATLAS", 
    "A_BOOK_AUDIOBOOK", 
    "A_BOOK_GLOBE", 
    "A_BOOK_MAGAZINE", 
    "A_BOOK_MAP", 
    "A_BOOKS_GEN", 
    "A_CLTH_BABY", 
    "A_CLTH_CHILD", 
    "A_CLTH_PROTECTIVE", 
    "A_FOOD_ANIMALFOOD", 
    "A_FOOD_ANIMALMED", 
    "A_FOOD_ANIMALVITAMINS", 
    "A_FOOD_CAKEDECOR", 
    "A_FOOD_CANFRUIT", 
    "A_FOOD_CEREALBARS", 
    "A_FOOD_CEREALCHOCBARS", 
    "A_FOOD_CHOCEREAL", 
    "A_FOOD_CNDY", 
    "A_FOOD_COFFEE", 
    "A_FOOD_DAIRY", 
    "A_FOOD_DESSERT", 
    "A_FOOD_DRIEDFRUIT", 
    "A_FOOD_FLOUR", 
    "A_FOOD_GEN", 
    "A_FOOD_MEATCHICKEN", 
    "A_FOOD_MISCBEVERAGE", 
    "A_FOOD_NAAN", 
    "A_FOOD_NCARBWTR", 
    "A_FOOD_OIL", 
    "A_FOOD_PASTANOODLE", 
    "A_FOOD_PASTRYCASE", 
    "A_FOOD_PETFOOD34", 
    "A_FOOD_PETFOOD", 
    "A_FOOD_PLAINBISCUIT", 
    "A_FOOD_PLAINCRACKER", 
    "A_FOOD_PLAINNUT", 
    "A_FOOD_RICE", 
    "A_FOOD_SEASONINGS", 
    "A_FOOD_SNACK",
    'A_FOOD_TEA',
    "A_GEN_STANDARD",
    "A_HLTH_PILLCAPSULETABLET",
    "A_HPC_DIETARYSUPPL",
    "A_HLTH_VITAMINS",
    "A BOOK MAGAZINE",
    "A BOOK ADULT",
    "A FOOD CEREALBARS",
    "A FOOD PLAINCRACKER",
    "A FOOD SODAJUICE",
    "A FOOD SODAJUICEUNSWEET",
    "A FOOD SPREAD",
    "A FOOD SWEETNER",
    "A FOOD TEA",
    "A FOOD VEGETABLE",
    "A FOOD ANIMALFOOD",
    "A HLTH NUTRITIONBAR",
    "A HLTH NUTRITIONDRINK",
    "A HLTH SMOKINGCESSATION",
    "A HLTH SMOKINGGUM",
    "A HPC CONTACTLENSES",
    "A HPC CONTRACEPTIVE",
    "A HPC INCONTINENCE",
    "A HPC MEDICINE",
    "A HPC PPECLOTHING",
    "A HPC PPEMASKS",
    "A HPC PPESANITISER",
    "A HPC CORRECTIVEGLASSES",
    "A HPC SANITARYPRODUCTS",
    "A HPC SANITARYPRODUCTSWASHABLE",
    "A HPC THERMOMETER",
    "A HPC WALKINGSTICK",
    "A HPC WHEELCHAIR",
    "A OUTDOOR FUEL",
    "A OUTDOOR FERTILIZER",
    "A OUTDOOR LAWNCONTROL",
    "A OUTDOOR PLANTFOOD",
    "A OUTDOOR PLANTS",
    "A OUTDOOR SEEDS",
    "A BABY BIBCLOTH",
    "A GEN NOTAX"
  ];

  const dropdownFields = {
    manufacturer: 'manufacturers',
    brand: 'brands',
    status: 'status',
    type: 'productTypes',
    packaging_unit: 'packagingUnits',
    product_category_hazmat: 'hazmatCategory',
    product_category_food: 'foodCategory',
    product_category_electronics: 'electronicsCategory',
    product_category_pharmacy: 'pharmacyCategory',
    article_packaging_type: 'articlePackagingType',
    product_country_of_origin: 'countries',
    marketplaces: 'marketplaces',
    marketplace: 'marketplaces',
    seller: 'sellers',
    fee_category: 'amazonCategory',
    fulfillment_vendor: 'fulFillmentVendors',
    tax: 'taxRates',
    amazon_tax_code: 'tax_codes',
    marketplace_id_type: 'marketplace_id_types'
  }


  if (field === 'status' || field === 'advertising_status') {
    let badge = PRODUCT_STATUSES[value];
    if (moduleName === 'importJobs') {
      badge = IMPORT_STATUSES[value];
    }
    if( moduleName === 'DocumentUploads'){
      badge = DOCUMENT_UPLOAD_STATUSES[value];

    }
    return (
      <td>
      
        {!show && <span className='cursor-pointer' onDoubleClick={() => showInputField(field)}>{badge}</span> }
        { show && show == editItem && dropdownFields[field] && (
          <div className="custom_select" style={{minWidth: '100px'}}>
            <Select
              options={convertDataToOptions(PRODUCT_STATUS_OPTIONS)}
              defaultValue={createDefaultOption(PRODUCT_STATUS_OPTIONS.find(x => x.id == value))}
              onChange={(e) => saveChange({index, value: e.value, field})}
              menuPortalTarget={document.body}
            />
          </div>
        ) }

        { show && show == editItem && (
          <Actions save={() => {
            submitForm(index, field)
            setShow(null)
          }} close={() => saveChange({index, value: false, field: "edit"})} />   
        ) }
      </td>
    );
  }

  if(field === 'error' && moduleName == 'products'){
    return (
      <>
            { openMarketplaceIssuesModal && <MarketplaceIssuesModal 
                                      sku={null} 
                                      marketplaceId={1} 
                                      amazonStatus={''} 
                                      marketplaceErrors={value.split(';')} 
                                      setModalOpen={openMarketplaceIssuesModal}
                                      handleModalClose={() => setOpenMarketplaceIssuesModal(false)}
                                /> }
          <td style={{textAlign: 'center', cursor: 'pointer'}} onClick={() => value ? setOpenMarketplaceIssuesModal(true) : null}>{ value ? '⚠️' : '✅' }</td>
      </>

    )
  }

  if (field === 'type' && moduleName == 'manufacturers') {
    return <td>{types.find(x => x.value == value)?.label}</td>;
  }

  switch (typeof value) {
    case 'boolean':
      value = value ? 'active' : 'disabled';
      break;

    case 'string':
      if(isListing && field === 'marketplace_name'){
        let img = 'de'
        if(value.split('.').length > 1){
          img = value.split('.')[1]
        }
        value = <li className="_li" key={value}> 
            <img className='flag' src={`/assets/images/flags/${img}.svg`} alt='' />
            {value} 
          </li>;

      }else if (field === 'data') {
        value = Object.keys(JSON.parse(value)[0]).map(item => {
          return <li key={item}> {item} </li>;
        });

        value = <ul>{value}</ul>;
      }
      break;

    case 'object':
      if (value) {
        console.log('never ');
        value = Object.keys(value).map((child, i) => {
          if (child !== '__typename' && i === 1) {
            return (
              <p key={i}>
                <strong className="text-capitalize">{child} :</strong> {value[child]}
              </p>
            );
          } else {
            return null;
          }
        });
      }

      break;

    default:
      break;
  }

  const isLink = field === 'name';
  const pageArr = ['manufacturers', 'products', 'listings'];
  const itemUrl = id => {
    if(pageArr.includes(moduleName)){
      if(isListing){
        return `/listings/${item.slug}/edit`;
      }
     return `/${moduleName}/${item.slug}/edit`;
    } else if (moduleName !== 'importJobs') {
     return `/${moduleName}/${id}/edit`;
    } else {
     return `/products/import/${id}`;
    }
  };


  if(selectData){
    selectData['brands'] = brands
    selectData['tax_codes'] = convertNameToObject(taxCodesData)
    selectData['marketplace_id_types'] = convertNameToObject(['ASIN', 'SKU'])
  }


  return (
    <td className={`${notClickable ? '' : 'cursor-pointer'} ${isLink ? 'td-link' : ''}`} onClick={onClick}>

      {notClickable ? (
        field === 'embeddedLocation' ? (
          <Link target="_blank" style={{ color: '#00488c' }} to={'/' + value} title={value}><span>{value}</span></Link>
        ) : (
          <>
            {show !== editItem && <span title={value} onDoubleClick={() => showInputField(field) } style={{ width: '100%', display: 'block' }}>{value??''}</span> }
            { show && show == editItem && dropdownFields[field] && (
              <div className="custom_select" style={{minWidth: '120px', position: 'relative'}}>
                <Select
                  id={field}
                  name={field}
                  options={convertDataToOptions(selectData[dropdownFields[field]])}
                  defaultValue={createDefaultOption(selectData[dropdownFields[field]].find(x => x.name == value))}
                  onChange={(e) => saveChange({index, value: e.label, field})}
                  menuPortalTarget={document.body}
                />             
              </div>
            ) }
            { show && show == editItem && !dropdownFields[field] && <input type="text" id={field} re className='form-control' defaultValue={value} onChange={(e) => {
              value = e.target.value
              saveChange({index, value: e.target.value, field})
            }} /> }

            { show && show == editItem && (
                <Actions save={() => {

                  submitForm(index, field)
                  setShow(null)
                }} close={() => {
                  saveChange({index, value: false, field: "edit"})}
                 } />   
            ) }
          </>
        )
      ) : (
        <Link style={{color: 'inherit', display: 'block'}} to={itemUrl(item.id || item.product_parent_id)} title={value}>
          {value}
        </Link>
      )}
    </td>
  );
};


const Actions = ({save, close}) => {
  return (
    <div className="action_buttons">
      <div className="action_btn save_btn" onClick={save}>
        <i class="fas fa-check"></i>
      </div>
      <div className="action_btn close_btn" onClick={close}>
        <i class="fas fa-times"></i>
      </div>
    </div>
  )
}

export default memo(Td);
