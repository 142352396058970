import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Apollo */
import { useQuery } from '@apollo/react-hooks';

/** Components */
import Table from '../../components/table/Table';

/** Services */
import { getManufacturers as QUERY } from '../../services/api/queryService';
import { queryStringHandler, fetchData, capitalize } from '../../services/util/dataHandlerService';

/** Routes */
import Routes from '../../lib/constants/routes';
import Pagination from '../../components/ui-components/pager/Pagination';

const description = {
  module: 'manufacturers',
  description: 'Manufacturers',
  req: 'searchManufacturers',
  url: Routes.manufacturers.index,
  is_product: false
};

const MediaBudget = ({ location, create, canDelete }) => {
  const { user_type, manufacturers } = useSelector(state => state.auth.user);
  const { first, page, keyword } = queryStringHandler(location.search);
  const [ activeManufacturers, setActiveManufacturers] = useState([]);
  const [ filter, setFilter] = useState(''); 
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const storageActiveManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
    if(storageActiveManufacturers){
      setActiveManufacturers(storageActiveManufacturers);
    }
  }, []);

  window.addEventListener('activeManufacturerChange', (e) => {
    setActiveManufacturers(JSON.parse(localStorage.getItem('active_manufacturers')));
  }); 

  useEffect(() => { 
    if(user_type === 1){
        setFilter(manufacturers.map(id => `id[]=${id}`).join('&'))
      }
    if(user_type === 2 && activeManufacturers){
        setFilter(activeManufacturers.filter(x => parseInt(x.id)).map(aManu => `id[]=${aManu.id}`).join('&'))
      }
  }, [activeManufacturers]);

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
      first,
      page,
      input: keyword,
      order: 'id',
      filter: filter
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if(data){
      data.searchManufacturers.data.map(x => {
        if(x.commission_type !== 'Goods consignment'){
          x.commission_percentage = 0
        }

        return x
      })
    }
  }, [data])

  if (error) {
    throw new Error(error);
  }

  const tableCells = []

  return (
    <>
      {/* Start Content */}
      {/* start page title */}
      <div className="row">
        <div className="col-12">
            <div className="d-flex mb-2">
                <div style={{ position: 'relative' }}>
                    <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        style={{ height: '36px', width: '70px' }}
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        Create
                    </button>
                    { menuOpen && (
                        <div className="dropdown__menu" style={{bottom: "-70px"}}>
                            <button className="dropdown__menu__item" onClick={() => {}}>
                                <p>Parent data</p>
                            </button>

                            <button className="dropdown__menu__item" onClick={() => {}}>
                                <p>Child data</p>
                            </button>

                        </div>
                    ) }
                </div>
            </div>
        </div>
      </div>
      {/* end page title */}

      {!data ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <>
            <div className="table-responsive">
            <table className="table mt-3">
                <thead>
                <tr>
                    <th></th>
                    <th key={'name'}>Media Portfolios</th>
                    <th key={'name'}>Status</th>
                    <th key={'name'}>Period</th>
                    <th key={'name'}>Client</th>
                    <th key={'name'}>Media Type</th>
                    <th key={'name'}>Budget Type</th>
                    <th key={'name'}>APN</th>
                    <th key={'name'}>Channel</th>
                    <th key={'name'}>Pre-shift %</th>
                    <th key={'name'}>Post-shift %</th>
                    <th key={'name'}>Budget Flexibility</th>
                    <th key={'name'}>Total Signed Portfolio Budget (net €)</th>
                    <th key={'name'}>Adjusted Budget (€)</th>
                    <th key={'name'}>Actual Spend (€) </th>
                    <th key={'name'}>Delta Budget (€)</th>
                    <th key={'name'}>Budget Reallocation (€)</th>
                    <th key={'name'}>Absolute Remaining Budget (€)</th>
                    <th key={'name'}>Budget Adjusted</th>
                    <th key={'name'}>Target KPI</th>
                    <th key={'name'}>Scoro Quote (URL)</th>
                    <th key={'name'}>Scoro Order (URL)</th>

                </tr>
                </thead>
                <tbody>
                { tableCells.map((row, rowIndex) =>  (
                    
                    <React.Fragment key={rowIndex}>
                        <tr
                            className={rowIndex % 2 === 0 ? 'gray' : 'white'}
                            style={{ cursor: 'pointer' }}
                            onClick={null}
                        >
                            <td style={{ maxWidth: '10px', overflow: 'visible' }}>
                            <i className="fas fa-plus-square" />
                            </td>
                            {row.map((cell, cellIndex) => (
                            <td
                                key={`${rowIndex}-${cellIndex}`}
                                style={{ maxWidth: cell && cell.type === 'span' ? 'unset' : '175px' }}
                            >
                                {cell}
                            </td>
                            ))}
                        </tr>
                        <tr className={rowIndex % 2 === 0 ? 'gray' : 'white'}>
                            <td colSpan={row.length + 1} className="p-0">
                                <div className="container col-12 extended-values">
                                    <div className="row row-cols-3">
                                        <div className="col-md-3 mb-1" key={`1`}>
                                            <strong>{1}: </strong>
                                            {'asdas'}
                                        </div>
                                    </div>
                                </div>
                            </td>   
                        </tr>
                    </React.Fragment>
                ))}
                </tbody>
            </table>
            </div>
        </>
      )}
    </>
  );
};

export default MediaBudget;
